<template>
    <b-sidebar id="add-new-visit-sidebar" :visible="isAddNewVisitSidebarActive" bg-variant="white"
        sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
        @change="(val) => $emit('update:is-add-new-visit-sidebar-active', val)">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
                <h5 class="mb-0">{{ $t("Add") }} {{ $t("New") }} Type Visit</h5>

                <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
            </div>

            <!-- BODY -->
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <!-- Full Name -->
                    <validation-provider #default="validationContext" name="name" rules="required">
                        <b-form-group :label="$t('Form.Name')" label-for="name">
                            <b-form-input id="name" v-model="stateData.name" autofocus
                                :state="getValidationState(validationContext)" trim placeholder="Type Visit name" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- prefix -->
                    <validation-provider #default="validationContext" name="prefix" rules="required">
                        <b-form-group label="Prefix" label-for="prefix">
                            <b-form-input id="name" v-model="stateData.prefix" autofocus
                                :state="getValidationState(validationContext)" trim placeholder="Type visit prefix" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- color -->
                    <validation-provider #default="validationContext" name="color">
                        <b-form-group label="Color" label-for="color">
                            <input type="color" id="color" v-model="stateData.color" class="form-control" />

                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- status -->
                    <validation-provider #default="validationContext" name="Status" rules="required">
                        <b-form-group :label="$t('Form.Status')" label-for="status">
                            <treeselect id="status" v-model="stateData.status"
                                :state="getValidationState(validationContext)" :options="optionsStatus"
                                :normalizer="normalizer" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Price -->
                    <validation-provider #default="validationContext" name="Price" rules="required">
                        <b-form-group label="Price" label-for="Price">
                            <treeselect id="name" v-model="stateData.consult_prices_id"
                                :load-options="searchFilter" :options="prices" :normalizer="normalizerPrice" />
                            <b-form-invalid-feedback>
                                {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>

                    <!-- Form Actions -->
                    <div class="d-flex mt-2">
                        <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
                            <span v-if="!loading">Add</span>
                            <span v-else>
                                <SpinnerLoading />
                            </span>
                        </b-button>
                        <b-button type="button" variant="outline-danger" @click="hide">
                            Cancel
                        </b-button>
                    </div>
                </b-form>
            </validation-observer>
        </template>
    </b-sidebar>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
} from "bootstrap-vue";
import ToastNotification from "@/components/ToastNotification";
import formValidation from "@/core/utils/forms/form-validation";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SpinnerLoading from "@/components/SpinnerLoading";

import { required } from "@validations";
import { ref } from "@vue/composition-api";

import axiosCT from "@/core/services/apiInsurance/admin/consults/visits";
import axiosAC from "@/core/services/apiInsurance/admin/consults/consultPrices";

export default {
    components: {
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        BButton,
        SpinnerLoading,
        // ToastNotification,

        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    model: {
        prop: "isAddNewVisitSidebarActive",
        event: "update:is-add-new-visit-sidebar-active",
    },
    props: {
        isAddNewVisitSidebarActive: {
            type: Boolean,
            required: true,
        },
        prices: {
            type: Array,
            required: false,
            default: () => []
        },
    },
    data() {
        return {
            required,
            normalizer(node) {
                return {
                    id: node.id,
                    label: node.name,
                };
            },
            normalizerPrice(node) {
                return {
                    id: node.id,
                    label: `${node.name} - ${node.price}`,
                };
            },
        };
    },
    setup(props, { emit }) {
        const searchFilter = ({ action, searchQuery, callback }) => {
            if (action === "ASYNC_SEARCH") {
                const datos = {
                    name: searchQuery,
                };
                axiosAC.consultPriceFilter(10, datos).then(({ data }) => {
                    callback(null, data);
                });
            }
        }
        const optionsStatus = [
            { name: "Active", id: true },
            { name: "Inactive", id: false },
        ];
        const stateDataTem = {
            name: "",
            status: true,
            consult_prices_id: null
        };
        const stateData = ref(JSON.parse(JSON.stringify(stateDataTem)));

        const resetuserData = () => {
            stateData.value = JSON.parse(JSON.stringify(stateDataTem));
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(
            resetuserData
        );

        const loading = ref(false);
        const onSubmit = () => {
            loading.value = true;
            if (stateData.value.color == undefined) {
                loading.value = false;
                emit("mensaje", "Check the color of the type visit");
                return
            }
            const datos = {
                ...stateData.value,
            };
            axiosCT
                .visitCreate(datos)
                .then(({ registro }) => {
                    loading.value = false;
                    resetuserData();
                    emit("createVisit", { status: true, registro });
                })
                .catch(() => {
                    loading.value = false;
                    emit("createVisit", { status: false });
                });
        };
        return {
            loading,
            stateData,
            optionsStatus,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            searchFilter,
        };
    },
};
</script>

<style></style>
